<template>
  <div>
    <PageTitle
      :title="$t('listing.candidates.title')"
      icon="mdi-account-group"
    ></PageTitle>
    <CandidatesList
      @add-candidate="redirectNewCandidate"
      @show-candidate-details="candidateDetails"
    />
  </div>
</template>
<script>
import CandidatesList from '@/components/candidates/CandidatesList.vue'
import PageTitle from '@/components/layout/PageTitle.vue'
import RouterMixin from '@/mixins/routerMixin'

export default {
  components: { PageTitle, CandidatesList },
  mixins: [RouterMixin],
  methods: {
    redirectNewCandidate() {
      this.routerPush('/candidates/details')
    },
    candidateDetails(candidateId) {
      this.routerPush(`/candidates/${candidateId}/details`)
    }
  }
}
</script>
