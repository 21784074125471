<template>
  <v-card class="pa-8">
    <v-row no-gutters>
      <v-col>
        <DataListTitle
          :title="`${totalItems} ${$t('listing.candidates.registered')}`"
          :is-loading="loading"
        ></DataListTitle>
      </v-col>
      <v-spacer />
      <v-col v-if="!loading" class="text-right">
        <v-btn color="primary" @click="$emit('add-candidate')">
          {{ $t('listing.candidates.create') }} <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div>
      <v-row no-gutters class="mb-5">
        <v-col cols="4">
          <v-text-field
            v-model="search"
            data-cy="input-search"
            append-icon="mdi-magnify"
            :label="$t('listing.candidates.search')"
            single-line
            hide-details
            @input="handleSearchChanged"
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="{
          'items-per-page-text': $t('listing.candidates.pagination'),
          'items-per-page-options': itemsPerPageOptions
        }"
        multi-sort
        @update:options="handleTableOptionUpdated"
      >

        <template v-slot:item.created_at="{ value }">
          <span>{{ formatDate(value) }}</span>
        </template>
        <template v-slot:item.updated_at="{ value }">
          <span>{{ formatDate(value) }}</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="py-2">
            <v-btn
              color="primary"
              class="ml-1"
              small
              @click="$emit('show-candidate-details', item.id)"
            >
              {{ $t('listing.candidates.details') }}
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>
<script>
import DataListTitle from '@/components/layout/DataListTitle.vue'
import DataTablesMixin from '@/mixins/dataTablesMixin'
import { filterManagement } from "@/services/filters"
import {mapActions, mapState} from 'vuex'
import DatesMixin from "@/mixins/datesMixin";
import SearchFilterMixin from '@/mixins/searchFilterMixin'

export default {
  components: {DataListTitle},
  mixins: [DataTablesMixin, DatesMixin, SearchFilterMixin],
  data() {
    return {
      headers: [
        {
          text: this.$t('listing.candidates.headers.last_name'),
          value: 'last_name',
          align: 'start'
        },
        {
          text: this.$t('listing.candidates.headers.first_name'),
          value: 'first_name'
        },
        {
          text: this.$t('listing.candidates.headers.created_at'),
          value: 'created_at',
        },
        {
          text: this.$t('listing.candidates.headers.updated_at'),
          value: 'updated_at',
        },
        {
          value: 'actions',
          align: 'right'
        }
      ]
    }
  },
  computed: {
    ...mapState('candidates', ['storedSearch']),
  },
  methods: {
    ...mapActions('candidates', ['getCandidates']),
    getDataFromApi () {
      this.loading = true

      const args = {
        ...filterManagement.getTableOptionsFromUrlQuery(this.$route),
        search: filterManagement.getSearchFromUrlQuery(this.$route),
      }

      this.getCandidates(args).then(data => {
        this.items = data.items
        this.totalItems = data.count
        this.loading = false
      })
    },
  }
}
</script>
